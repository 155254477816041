module.exports = {
  "featureFlags": {
    "dynamicSalutation": true,
    "header": {
      "replaceWithLogo": false
    },
    "map": {
      "enabled": true
    },
    "location": {
      "defaultAddress": null,
      "customLocationSlug": null
    },
    "startMenu": {
      "createProfile": true
    },
    "showInclusionReason": null,
    "feed": {
      "showFullDatetime": false,
      "showRecommendedPost": true
    },
    "factSheet": true,
    "search": {
      "hideSectionsForLoggedOut": false,
      "disableSearchForLoggedOut": false
    }
  },
  "plugins": {
    "surveys": {
      "enabled": false,
      "canCreate": ["press_profile"]
    },
    "messengerToggle": {
      "enabled": true
    },
    "nonCommentable": {
      "enabled": true,
      "canTrigger": ["press_profile", "private_profile", "blog_profile", "authority_profile"]
    }
  },
  "staticPages": {
    "enableBlog": true,
    "enableAboutUs": true,
    "enableFaq": true,
    "urls": {
      "AboutUs": "https://start.lokalportal.de",
      "Blog": "https://start.lokalportal.de/blog/",
      "Faq": "https://start.lokalportal.de/haeufige-fragen/",
      "Homepage": "https://lokalportal.de",
      "Legal": "https://lokalportal.de/static/impressum.html",
      "PrivacyPolicy": "https://lokalportal.de/static/datenschutz.html",
      "ReleaseNotes": "https://lkpt.de/versionshinweise-app",
      "TermsAndConditions": "https://lokalportal.de/static/agb.html",
      "Contact": "https://blog.lokalportal.de/kontakt/"
    }
  },
  "tabs": {
    "showNewsFeed": true,
    "showEventFeed": true,
    "showGroups": true,
    "showUserDirectory": true,
    "showNotifications": true
  },
  "languages": [
    {
      "language": "de",
      "isEnabled": true,
      "isDefault": true
    }
  ],
  "links": {
    "googlePlayStore": "https://play.google.com/store/apps/details?id=de.lokalportal.mainapp",
    "appleStore": "https://apps.apple.com/de/app/lokalportal/id1380768394"
  },
  "gtm": {
    "id": "GTM-M644ZVD"
  },
  "eyeable": {
    "id": null,
    "enabled": false
  },
  "cookiebot": {
    "id": "38b01a56-8357-44b9-8430-88cdbf8f4713",
    "enabled": true
  },
  "tutorialVideo": {
    "url": "fsdfsad"
  }
}
;